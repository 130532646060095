import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='About' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="About"
          subheader="howdelete.com supports privacy oriented movement within the Internet. 
          Thanks to our team and online resources we provide like articles, tutorials, services or code snippets we try to rise awareness of privacy and provide simultaneously tools that support it."
        />
        <h2>Mission</h2>
        <text>Provide online resources for rising privacy awareness among the Internet.</text><br></br>
        <h2>Vision</h2>
        <text>Thanks to Howdelete.com user is aware of his own rights and able to take conscious actions in the Internet.</text>
        <h2>What can you find on Howdelete.com?</h2>
        <li>Knowledge sharing</li>
        <li>Articles</li>
        <li>Video tutorials</li>
        <li>Code snippets</li>
        <li>Consultations</li>
        <li>Development</li>
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
