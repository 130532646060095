import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        This website has been created in order to grow
        people's awareness regarding web privacy. Thanks to the website, its tutorials and scripts
        management of own data in the Internet will be easier.
      </Text>
    </Card>
  </Section>
)

export default Commitment
